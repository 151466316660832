/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate HTTP proxy
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const TargetKind = {
    Http: 'Http',
    MySql: 'MySql',
    Ssh: 'Ssh',
    Postgres: 'Postgres',
    WebAdmin: 'WebAdmin'
};
export function instanceOfTargetKind(value) {
    for (const key in TargetKind) {
        if (Object.prototype.hasOwnProperty.call(TargetKind, key)) {
            if (TargetKind[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function TargetKindFromJSON(json) {
    return TargetKindFromJSONTyped(json, false);
}
export function TargetKindFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function TargetKindToJSON(value) {
    return value;
}
