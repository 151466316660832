/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate HTTP proxy
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ExistingApiToken interface.
 */
export function instanceOfExistingApiToken(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('label' in value) || value['label'] === undefined)
        return false;
    if (!('created' in value) || value['created'] === undefined)
        return false;
    if (!('expiry' in value) || value['expiry'] === undefined)
        return false;
    return true;
}
export function ExistingApiTokenFromJSON(json) {
    return ExistingApiTokenFromJSONTyped(json, false);
}
export function ExistingApiTokenFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'label': json['label'],
        'created': (new Date(json['created'])),
        'expiry': (new Date(json['expiry'])),
    };
}
export function ExistingApiTokenToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'label': value['label'],
        'created': ((value['created']).toISOString()),
        'expiry': ((value['expiry']).toISOString()),
    };
}
