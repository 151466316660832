/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate HTTP proxy
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ExistingApiTokenFromJSON, ExistingApiTokenToJSON, } from './ExistingApiToken';
/**
 * Check if a given object implements the TokenAndSecret interface.
 */
export function instanceOfTokenAndSecret(value) {
    if (!('token' in value) || value['token'] === undefined)
        return false;
    if (!('secret' in value) || value['secret'] === undefined)
        return false;
    return true;
}
export function TokenAndSecretFromJSON(json) {
    return TokenAndSecretFromJSONTyped(json, false);
}
export function TokenAndSecretFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'token': ExistingApiTokenFromJSON(json['token']),
        'secret': json['secret'],
    };
}
export function TokenAndSecretToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'token': ExistingApiTokenToJSON(value['token']),
        'secret': value['secret'],
    };
}
