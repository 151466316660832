/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate HTTP proxy
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the StartSloResponseParams interface.
 */
export function instanceOfStartSloResponseParams(value) {
    if (!('url' in value) || value['url'] === undefined)
        return false;
    return true;
}
export function StartSloResponseParamsFromJSON(json) {
    return StartSloResponseParamsFromJSONTyped(json, false);
}
export function StartSloResponseParamsFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'url': json['url'],
    };
}
export function StartSloResponseParamsToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'url': value['url'],
    };
}
