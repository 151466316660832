/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate HTTP proxy
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CredentialKindFromJSON, CredentialKindToJSON, } from './CredentialKind';
/**
 * Check if a given object implements the UserRequireCredentialsPolicy interface.
 */
export function instanceOfUserRequireCredentialsPolicy(value) {
    return true;
}
export function UserRequireCredentialsPolicyFromJSON(json) {
    return UserRequireCredentialsPolicyFromJSONTyped(json, false);
}
export function UserRequireCredentialsPolicyFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'http': json['http'] == null ? undefined : (json['http'].map(CredentialKindFromJSON)),
        'ssh': json['ssh'] == null ? undefined : (json['ssh'].map(CredentialKindFromJSON)),
        'mysql': json['mysql'] == null ? undefined : (json['mysql'].map(CredentialKindFromJSON)),
        'postgres': json['postgres'] == null ? undefined : (json['postgres'].map(CredentialKindFromJSON)),
    };
}
export function UserRequireCredentialsPolicyToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'http': value['http'] == null ? undefined : (value['http'].map(CredentialKindToJSON)),
        'ssh': value['ssh'] == null ? undefined : (value['ssh'].map(CredentialKindToJSON)),
        'mysql': value['mysql'] == null ? undefined : (value['mysql'].map(CredentialKindToJSON)),
        'postgres': value['postgres'] == null ? undefined : (value['postgres'].map(CredentialKindToJSON)),
    };
}
