/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate HTTP proxy
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const CredentialKind = {
    Password: 'Password',
    PublicKey: 'PublicKey',
    Totp: 'Totp',
    Sso: 'Sso',
    WebUserApproval: 'WebUserApproval'
};
export function instanceOfCredentialKind(value) {
    for (const key in CredentialKind) {
        if (Object.prototype.hasOwnProperty.call(CredentialKind, key)) {
            if (CredentialKind[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function CredentialKindFromJSON(json) {
    return CredentialKindFromJSONTyped(json, false);
}
export function CredentialKindFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function CredentialKindToJSON(value) {
    return value;
}
