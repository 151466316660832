/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate HTTP proxy
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ExistingPublicKeyCredential interface.
 */
export function instanceOfExistingPublicKeyCredential(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('label' in value) || value['label'] === undefined)
        return false;
    if (!('abbreviated' in value) || value['abbreviated'] === undefined)
        return false;
    return true;
}
export function ExistingPublicKeyCredentialFromJSON(json) {
    return ExistingPublicKeyCredentialFromJSONTyped(json, false);
}
export function ExistingPublicKeyCredentialFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'label': json['label'],
        'dateAdded': json['date_added'] == null ? undefined : (new Date(json['date_added'])),
        'lastUsed': json['last_used'] == null ? undefined : (new Date(json['last_used'])),
        'abbreviated': json['abbreviated'],
    };
}
export function ExistingPublicKeyCredentialToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'label': value['label'],
        'date_added': value['dateAdded'] == null ? undefined : ((value['dateAdded']).toISOString()),
        'last_used': value['lastUsed'] == null ? undefined : ((value['lastUsed']).toISOString()),
        'abbreviated': value['abbreviated'],
    };
}
