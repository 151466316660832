/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate HTTP proxy
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const ApiAuthState = {
    NotStarted: 'NotStarted',
    Failed: 'Failed',
    PasswordNeeded: 'PasswordNeeded',
    OtpNeeded: 'OtpNeeded',
    SsoNeeded: 'SsoNeeded',
    WebUserApprovalNeeded: 'WebUserApprovalNeeded',
    PublicKeyNeeded: 'PublicKeyNeeded',
    Success: 'Success'
};
export function instanceOfApiAuthState(value) {
    for (const key in ApiAuthState) {
        if (Object.prototype.hasOwnProperty.call(ApiAuthState, key)) {
            if (ApiAuthState[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function ApiAuthStateFromJSON(json) {
    return ApiAuthStateFromJSONTyped(json, false);
}
export function ApiAuthStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiAuthStateToJSON(value) {
    return value;
}
